<template>
  <div v-if="period">
    <h3 class="flix-html-h3">{{ $t('message.bookingPeriod') }}</h3>
    <select class="flix-form-control flix-form-group" v-model="selected.period" @change="setChange()">
      <option v-for="(value, index) in period" v-show="index" :key="index" :value="index">{{ value }}</option>
    </select>
  </div>
</template>
<script>
export default {
  components: {},
  props: { data: Object, callback: Function },
  data () {
    return {
      selected: {
        period: this.data.period
      },
      period: {}
    }
  },
  watch: {
    '$i18n.locale' () { this.getPeriod() }
  },
  methods: {
    getPeriod () {
      this.period = {
        '2 days': '2 ' + this.$t('message.days'),
        '7 days': '7 ' + this.$t('message.days'),
        '2 weeks': '14 ' + this.$t('message.days'),
        '1 months': '1 ' + this.$t('message.month'),
        '2 months': '2 ' + this.$t('message.months'),
        '3 months': '3 ' + this.$t('message.months'),
        '6 months': '6 ' + this.$t('message.months'),
        '12 months': '12 ' + this.$t('message.months')
      }
    },
    setChange () {
      var data = this.selected.period.split(' ')
      data[0] = data[0] * 1
      this.callback(data)
    }
  },
  mounted () {
    this.getPeriod()
    if (this.data.include.period.length) {
      this.selected.period = this.data.include.period[0] + ' ' + this.data.include.period[1]
    } else {
      this.selected.period = '2 months'
    }
    this.setChange()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
